<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit' && pageActive == '产品信息'">
      <span @click="pageToUrl('organization_xp')">基本信息</span>
      <span class="active">产品信息</span>
    </div>
    <div class="header" v-if="pageType == 'edit' && pageActive == '基本信息'">
      <span @click="pageToUrl('organization_xp')" class="active">基本信息</span>
      <span @click="pageClick('产品信息')">产品信息</span>
    </div>
    <div class="header" v-if="pageType != 'edit'">
      <span
        @click="navClick('基本信息')"
        :class="crumbs == '基本信息' ? 'active' : 'left'"
        >基本信息</span
      >
      <span
        @click="navClick('产品信息')"
        :class="crumbs == '产品信息' ? 'active' : 'left'"
        >产品信息</span
      >
    </div>
    <!-- 基本信息 -->
    <div v-if="crumbs == '基本信息'">
      <div class="desc">
        <h5 class="jsxx">基本信息</h5>
      </div>
      <div class="input_con">
        <ul class="inputBox">
          <li>
            <span><i>*</i>机构名称</span>
            <el-input
              class="inputs"
              v-model="orgDetail.orgName"
              maxlength="128"
              placeholder="输入机构名称"
              tabindex="1"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>机构编号</span>
            <el-input
              class="inputs"
              v-model="orgDetail.orgNo"
              maxlength="128"
              placeholder="输入机构编号"
              tabindex="2"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>联系人</span>
            <el-input
              class="inputs"
              v-model="orgDetail.contactName"
              maxlength="128"
              placeholder="输入联系人"
              tabindex="3"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>手机号码</span>
            <el-input
              class="inputs"
              v-model="orgDetail.phoneNumber"
              maxlength="11"
              placeholder="输入手机号码"
              tabindex="4"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>邮箱</span>
            <el-input
              class="inputs"
              v-model="orgDetail.contactEmail"
              maxlength="128"
              placeholder="输入邮箱"
              tabindex="5"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>客服电话</span>
            <el-input
              class="inputs"
              v-model="orgDetail.servicePhone"
              maxlength="12"
              placeholder="输入客服电话"
              tabindex="6"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>网址</span>
            <el-input
              class="inputs"
              v-model="orgDetail.site"
              maxlength="128"
              placeholder="输入网址"
              tabindex="7"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>联系地址</span>
            <el-input
              class="inputs"
              v-model="orgDetail.contactAddress"
              maxlength="128"
              placeholder="输入联系地址"
              tabindex="8"
            ></el-input>
          </li>
          <li>
            <span>备注</span>
            <el-input
              class="inputs"
              v-model="orgDetail.remark"
              maxlength="128"
              placeholder="输入备注"
              tabindex="9"
            ></el-input>
          </li>
        </ul>
      </div>
      <div class="bottom" style="margin-bottom: 18px" v-if="pageType == 'edit'">
        <el-button @click="getBack" class="back">返回</el-button>
        <el-button @click="submit" class="next" type="primary">保存</el-button>
      </div>
      <div class="bottom" style="margin-bottom: 18px" v-else>
        <el-button @click="navClick('产品信息')" class="next" type="primary"
          >下一步</el-button
        >
      </div>
    </div>
    <!-- 产品信息 -->
    <div
      v-auth="pageType == 'edit' ? 'ACCOUNT:ORG:ORGS:CONFIG' : 'ALL'"
      v-if="crumbs == '产品信息'"
    >
      <div class="desc">
        <h5 class="jsxx">支付产品</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 153px">
              <p>产品类型</p>
            </td>
            <td style="width: 281px">
              <p>产品通道</p>
            </td>
            <td style="width: 176px">
              <p>结算费率(%)</p>
            </td>
            <td style="width: 300px">
              <p>大额费率(%)</p>
            </td>
            <td style="width: 129px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in payChannels" :key="i">
            <td style="width: 153px">
              <p>{{ v.productName }}</p>
            </td>
            <td style="width: 281px">
              <p>{{ v.payChannelName }}</p>
            </td>
            <td style="width: 176px">
              <p>{{ v.settleRate }}</p>
            </td>
            <td style="width: 300px">
              <p>{{ v.largeRate }}</p>
            </td>
            <td style="width: 129px">
              <p>
                <i
                  v-auth="
                    pageType == 'edit' ? 'ACCOUNT:ORG:ORGS:CONFIG/EDIT' : 'ALL'
                  "
                  class="lianjie"
                  @click="editPayTab(v, i)"
                  >编辑</i
                >
                <i
                  v-auth="
                    pageType == 'edit'
                      ? 'ACCOUNT:ORG:ORGS:CONFIG/DELETE'
                      : 'ALL'
                  "
                  class="lianjie"
                  style="padding-left: 7px"
                  @click="delPayTab(i, v)"
                  >删除</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="pageType == 'edit' ? 'ACCOUNT:ORG:ORGS:CONFIG/ADD' : 'ALL'"
        class="xzjsxx"
        @click="addPayShow"
      >
        <span>+</span> 新增支付产品
      </h6>
      <div class="desc">
        <h5 class="jsxx">代付产品</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 153px">
              <p>产品类型</p>
            </td>
            <td style="width: 179px">
              <p>产品通道</p>
            </td>
            <td style="width: 148px">
              <p>费率(%)</p>
            </td>
            <td style="width: 221px">
              <p>对公代付(元/笔)</p>
            </td>
            <td style="width: 197px">
              <p>对私代付(元/笔)</p>
            </td>
            <td style="width: 153px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in agnpayChannels" :key="i">
            <td style="width: 153px">
              <p>
                {{
                  v.payType == "BANK_PAY_DAILY_ORDER"
                    ? "朝夕付"
                    : v.payType == "BANK_PAY_MAT_ENDOWMENT_ORDER"
                    ? "任意付"
                    : ""
                }}
              </p>
            </td>
            <td style="width: 179px">
              <p>{{ v.agnpayChannelName }}</p>
            </td>
            <td style="width: 148px">
              <p>{{ v.payRate }}</p>
            </td>
            <td style="width: 221px">
              <p>{{ v.payCostPublic | formatMoney }}</p>
            </td>
            <td style="width: 197px">
              <p>{{ v.payCostPrivate | formatMoney }}</p>
            </td>
            <td style="width: 153px">
              <p>
                <i
                  v-auth="
                    pageType == 'edit' ? 'ACCOUNT:ORG:ORGS:CONFIG/EDIT' : 'ALL'
                  "
                  class="lianjie"
                  @click="editUserPayTab(v, i)"
                  >编辑</i
                >
                <i
                  v-auth="
                    pageType == 'edit'
                      ? 'ACCOUNT:ORG:ORGS:CONFIG/DELETE'
                      : 'ALL'
                  "
                  class="lianjie"
                  style="padding-left: 7px"
                  @click="delUserPayTab(i, v)"
                  >删除</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="pageType == 'edit' ? 'ACCOUNT:ORG:ORGS:CONFIG/ADD' : 'ALL'"
        class="xzjsxx"
        @click="addUserPayShow"
      >
        <span>+</span> 新增代付产品
      </h6>
      <div class="desc">
        <h5 class="jsxx">分账产品</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 153px">
              <p>产品类型</p>
            </td>
            <td style="width: 281px">
              <p>产品通道</p>
            </td>
            <td style="width: 176px">
              <p>费率(%)</p>
            </td>
            <td style="width: 300px">
              <p>结算成本(元/笔)</p>
            </td>
            <td style="width: 129px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in allocChannels" :key="i">
            <td style="width: 153px">
              <p>
                {{
                  v.settleType == "AUTO"
                    ? "自动结算"
                    : v.settleType == "MANUAL"
                    ? "手工结算"
                    : v.settleType == "MANUAL_INSTANT"
                    ? "手工即时结算"
                    : ""
                }}
              </p>
            </td>
            <td style="width: 281px">
              <p>{{ v.allocChannelName }}</p>
            </td>
            <td style="width: 176px">
              <p>{{ v.settleRate }}</p>
            </td>
            <td style="width: 300px">
              <p>{{ v.settleCost | formatMoney }}</p>
            </td>
            <td style="width: 129px">
              <p>
                <i
                  v-auth="
                    pageType == 'edit' ? 'ACCOUNT:ORG:ORGS:CONFIG/EDIT' : 'ALL'
                  "
                  class="lianjie"
                  @click="editSplitTab(v, i)"
                  >编辑</i
                >
                <i
                  v-auth="
                    pageType == 'edit'
                      ? 'ACCOUNT:ORG:ORGS:CONFIG/DELETE'
                      : 'ALL'
                  "
                  class="lianjie"
                  style="padding-left: 7px"
                  @click="delSplitTab(i, v)"
                  >删除</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6
        v-auth="pageType == 'edit' ? 'ACCOUNT:ORG:ORGS:CONFIG/ADD' : 'ALL'"
        class="xzjsxx"
        @click="addSplitShow"
      >
        <span>+</span> 新增分账产品
      </h6>
      <p
        style="text-align: center; margin-top: 50px; margin-bottom: 18px"
        v-if="pageType != 'edit'"
      >
        <el-button class="back" @click="navClick('基本信息')">上一步</el-button>
        <el-button class="next" :disabled="repeatFlag" @click="submit"
          >完成</el-button
        >
      </p>
    </div>

    <!-- 新增支付产品弹框 -->
    <el-dialog
      :title="
        editPayIndex || editPayIndex === 0 ? '修改支付产品' : '新增支付产品'
      "
      :visible.sync="userPayShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="addPayFrom.productId"
            placeholder="选择产品类型"
            class="width100"
            tabindex="1"
          >
            <el-option
              v-for="item in payDropLists"
              :key="item.productId"
              :label="item.productName"
              :value="item.productId"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>结算费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addPayFrom.settleRate"
            min="0"
            max="100"
            placeholder="输入结算费率"
            class="width100"
            tabindex="3"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>产品通道</div>
          <el-input
            v-model="addPayFrom.payChannelName"
            maxlength="128"
            placeholder="输入产品通道"
            class="width100"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title">大额费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addPayFrom.largeRate"
            min="0"
            max="100"
            placeholder="输入大额费率"
            class="width100"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="userPayShow = false">取 消</el-button>
        <el-button class="addBt" :disabled="repeatFlag" @click="addPay"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增代付产品弹框 -->
    <el-dialog
      :title="
        editUserPayIndex || editUserPayIndex === 0
          ? '修改代付产品'
          : '新增代付产品'
      "
      :visible.sync="hasPayShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="addUserPayFrom.payType"
            placeholder="选择产品类型"
            tabindex="1"
          >
            <el-option
              v-for="item in userPayDropLists"
              :key="item.value"
              :label="item.title"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addUserPayFrom.payRate"
            placeholder="输入费率"
            min="0"
            max="100"
            class="width100"
            tabindex="3"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>对私代付(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addUserPayFrom.payCostPrivate"
            min="0"
            placeholder="输入价格"
            class="width100"
            tabindex="5"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>产品通道</div>
          <el-input
            v-model="addUserPayFrom.agnpayChannelName"
            maxlength="128"
            placeholder="输入产品通道"
            class="width100"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>对公代付(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addUserPayFrom.payCostPublic"
            min="0"
            placeholder="输入价格"
            class="width100"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="hasPayShow = false">取 消</el-button>
        <el-button class="addBt" :disabled="repeatFlag" @click="addUserPay"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增分账产品弹框 -->
    <el-dialog
      :title="
        editSplitIndex || editSplitIndex === 0 ? '修改分账产品' : '新增分账产品'
      "
      :visible.sync="accountShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>产品类型</div>
          <el-select
            v-model="addSplitFrom.settleType"
            placeholder="选择产品类型"
            class="width100"
            tabindex="1"
          >
            <el-option
              v-for="item in splitDropLists"
              :key="item.value"
              :label="item.title"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>费率(0-100%)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addSplitFrom.settleRate"
            placeholder="输入费率"
            min="0"
            max="100"
            class="width100"
            tabindex="3"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>产品通道</div>
          <el-input
            v-model="addSplitFrom.allocChannelName"
            maxlength="128"
            placeholder="输入产品通道"
            class="width100"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>结算成本(元/笔)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="addSplitFrom.settleCost"
            min="0"
            placeholder="输入结算成本"
            class="width100"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="accountShow = false">取 消</el-button>
        <el-button class="addBt" :disabled="repeatFlag" @click="addSplit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addOrganizations,
  detailOrganizations,
  setOrganizations,
  setPaychannels,
  delPaychannels,
  setAgnpaychannels,
  delAgnpaychannels,
  setAllocchannels,
  delAllocchannels,
} from "@/api/user/organizations.js";
import { payproductsDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
import { isTwoDecimal } from "@/utils/common.js";

export default {
  data() {
    return {
      value1: "",
      value2: "",
      crumbs: "基本信息",
      userPayShow: false, //新增支付展示
      hasPayShow: false, //新增代付展示
      accountShow: false, //新增分账展示
      orgDetail: {
        orgName: "",
        contactName: "",
        phoneNumber: "",
        servicePhone: "",
        contactEmail: "",
        site: "",
        contactAddress: "",
        remark: "",
      }, //基本信息
      payChannels: [], //支付通道列表
      agnpayChannels: [], //	代付通道列表
      allocChannels: [], //分账通道列表
      payDropLists: [], //支付下拉选择列表
      addPayFrom: {
        // 支付表表单
        payChannelName: "",
        productId: "",
        settleRate: "",
        largeRate: "",
      },
      editPayIndex: null, //修改的支付
      addUserPayFrom: {
        // 代付表单
        agnpayChannelName: "",
        payType: "",
        payRate: "",
        payCostPublic: "",
        payCostPrivate: "",
      },
      userPayDropLists: [
        {
          title: "朝夕付",
          value: "BANK_PAY_DAILY_ORDER",
          disabled: false,
        },
        {
          title: "任意付",
          value: "BANK_PAY_MAT_ENDOWMENT_ORDER",
          disabled: false,
        },
      ],
      editUserPayIndex: null, //修改的代付
      addSplitFrom: {
        // 分账表单
        allocChannelName: "",
        settleType: "",
        settleRate: "",
        settleCost: "",
      },
      splitDropLists: [
        {
          title: "自动结算",
          value: "AUTO",
          disabled: false,
        },
        {
          title: "手工结算",
          value: "MANUAL",
          disabled: false,
        },
        {
          title: "手工即时结算",
          value: "MANUAL_INSTANT",
          disabled: false,
        },
      ],
      editSplitIndex: null, //修改的分账
      pageType: "",
      pageActive: "",
      repeatFlag: false, //防止重复提交
    };
  },
  computed: {
    ...mapState({
      organDetail: "user_organDetail",
    }),
  },
  created() {
    this.getPayDrop();
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.crumbs = this.$route.query.active;
      this.pageActive = this.$route.query.active;
      this.getDetail();
    } else {
      if (this.organDetail) {
        this.orgDetail = this.organDetail.orgDetail;
        this.payChannels = this.organDetail.payChannels;
        this.agnpayChannels = this.organDetail.agnpayChannels;
        this.allocChannels = this.organDetail.allocChannels;
      }
    }
  },
  methods: {
    ...mapMutations({
      setOrgan: "user_setOrgan",
    }),
    // 获取详情
    getDetail() {
      detailOrganizations(this.$route.query.id).then((res) => {
        if (res) {
          this.orgDetail = res.resultData.orgDetail;
          this.payChannels = res.resultData.payChannels;
          this.agnpayChannels = res.resultData.agnpayChannels;
          this.allocChannels = res.resultData.allocChannels;
          this.agnpayChannels.forEach((v) => {
            v.payCostPublic /= 100;
            v.payCostPrivate /= 100;
          });
          this.allocChannels.forEach((v) => {
            v.settleCost /= 100;
          });
        }
      });
    },
    //菜单切换
    navClick(type) {
      if (type == "产品信息") {
        let data = JSON.parse(JSON.stringify(this.orgDetail));
        if (!data.orgName) {
          this.$message.error("机构名称必填");
          return;
        }
        if (!data.orgNo) {
          this.$message.error("机构编号必填");
          return;
        }
        if (!data.contactName) {
          this.$message.error("联系人必填");
          return;
        }
        if (!data.phoneNumber) {
          this.$message.error("手机号码必填");
          return;
        }
        if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.phoneNumber)) {
          this.$message.error("请正确填写手机号码");
          return;
        }

        if (!data.contactEmail) {
          this.$message.error("邮箱必填");
          return;
        } else {
          if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              data.contactEmail
            )
          ) {
            this.$message.error("邮箱格式不合法");
            return;
          }
        }
        if (!data.servicePhone) {
          this.$message.error("客服电话必填");
          return;
        } else {
          if (!/^[0-9\-]+$/.test(data.servicePhone)) {
            this.$message.error("请正确填写客服电话");
            return;
          }
        }
        if (!data.site) {
          this.$message.error("网址必填");
          return;
        }
        if (!data.contactAddress) {
          this.$message.error("联系地址必填");
          return;
        }
      }
      this.crumbs = type;
    },
    // 获取支付下拉选择
    getPayDrop() {
      payproductsDrop().then((res) => {
        this.payDropLists = res.resultData;
        this.payDropLists.forEach((v) => {
          v.disabled = false;
        });
      });
    },
    //新增支付展示
    addPayShow() {
      this.addPayFrom = {
        payChannelName: "",
        productId: "",
        settleRate: "",
        largeRate: "",
      };
      console.log("新增支付产品弹框数据为", this.addPayFrom);
      this.editPayIndex = null;
      this.userPayShow = true;
    },
    //确定新增支付产品
    addPay() {
      let data = JSON.parse(JSON.stringify(this.addPayFrom));
      if (!data.productId) {
        this.$message.error("产品类型必选");
        return;
      }
      if (!data.payChannelName) {
        this.$message.error("通道名称必填");
        return;
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("结算费率必填");
        return;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("结算费率不合法(最多保留小数点后两位)");
          return;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("结算费率范围（0-100）");
        return;
      }
      if (!data.largeRate && data.largeRate !== 0) {
        data.largeRate = data.settleRate;
      } else {
        if (data.largeRate < 0 || data.largeRate > 100) {
          this.$message.error("大额费率范围（0-100）");
          return;
        }
        if (!isTwoDecimal(data.largeRate)) {
          this.$message.error("大额费率不合法(最多保留小数点后两位)");
          return;
        }
      }

      this.repeatFlag = true;
      if (this.$route.query.pageType == "edit") {
        data.payChannelId = data.payChannelId ? data.payChannelId : 0;
        setPaychannels(this.$route.query.id, data).then((res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");
            this.getDetail();
            this.userPayShow = false;    
          }
          setTimeout(() => {
            this.repeatFlag = false;
          }, 2000);
        });
      } else {
        this.payDropLists.forEach((v) => {
          if (v.productId == data.productId) {
            data.productName = v.productName;
          }
        });
        if (this.editPayIndex !== null) {
          this.payChannels[this.editPayIndex] = data;
        } else {
          this.payChannels.push(data);
        }
        this.$message.success("保存成功");
        this.userPayShow = false;
        setTimeout(() => {
          this.repeatFlag = false;
        }, 2000);
      }
    },
    // 修改支付
    editPayTab(value, index) {
      this.addPayFrom = JSON.parse(JSON.stringify(value));
      this.userPayShow = true;
      this.editPayIndex = index;
    },
    // 删除支付
    delPayTab(index, value) {
      this.$confirm("此操作将删除该支付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delPaychannels(this.$route.query.id, value.payChannelId).then(
              (res) => {
                if (res.resultStatus) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.payChannels.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增代付展示
    addUserPayShow() {
      this.addUserPayFrom = {
        agnpayChannelName: "",
        payType: "",
        payRate: "",
        payCostPublic: "",
        payCostPrivate: "",
      };
      this.editUserPayIndex = null;
      this.hasPayShow = true;
    },
    //确定新增代付产品
    addUserPay() {
      let data = JSON.parse(JSON.stringify(this.addUserPayFrom));
      if (!data.payType) {
        this.$message.error("产品类型必选");
        return;
      }
      if (!data.agnpayChannelName) {
        this.$message.error("通道名称必填");
        return;
      }
      if (!data.payRate && data.payRate !== 0) {
        this.$message.error("代付费率必填");
        return;
      } else {
        if (!isTwoDecimal(data.payRate)) {
          this.$message.error("代付费率不合法(最多保留小数点后两位)");
          return;
        }
      }
      if (data.payRate < 0 || data.payRate > 100) {
        this.$message.error("代付费率范围（0-100）");
        return;
      }
      if (!data.payCostPublic && data.payCostPublic !== 0) {
        this.$message.error("对公代付必填");
        return;
      }
      if (data.payCostPublic < 0) {
        this.$message.error("对公代付最小金额为0元");
        return;
      }
      if (!data.payCostPrivate && data.payCostPrivate !== 0) {
        this.$message.error("对私代付必填");
        return;
      }
      if (data.payCostPrivate < 0) {
        this.$message.error("对私代付最小金额为0元");
        return;
      }
      if(data.payCostPublic.length > 8 || data.payCostPrivate.length > 8){
        this.$message.error("金额超过最大长度");
        return;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.payCostPublic)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.payCostPrivate))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      this.repeatFlag = true;
      if (this.$route.query.pageType == "edit") {
        data.payCostPublic *= 100;
        data.payCostPrivate *= 100;
        data.agnpayChannelId = data.agnpayChannelId ? data.agnpayChannelId : 0;
        setAgnpaychannels(this.$route.query.id, data).then((res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");           
            this.getDetail();
            this.hasPayShow = false;
          }
          setTimeout(() => {
            this.repeatFlag = false;
          }, 2000);
        });
      } else {
        if (this.editUserPayIndex !== null) {
          this.agnpayChannels[this.editUserPayIndex] = data;
        } else {
          this.agnpayChannels.push(data);
        }
        this.$message.success("保存成功");
        this.hasPayShow = false;
        setTimeout(() => {
          this.repeatFlag = false;
        }, 2000);
      }
    },
    // 修改代付
    editUserPayTab(value, index) {
      this.addUserPayFrom = JSON.parse(JSON.stringify(value));
      this.hasPayShow = true;
      this.editUserPayIndex = index;
    },
    // 删除代付
    delUserPayTab(index, value) {
      this.$confirm("此操作将删除该代付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delAgnpaychannels(this.$route.query.id, value.agnpayChannelId).then(
              (res) => {
                if (res.resultStatus) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.agnpayChannels.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //打开新增分账产品弹框
    addSplitShow() {
      this.addSplitFrom = {
        allocChannelName: "",
        settleType: "",
        settleRate: "",
        settleCost: "",
      };
      this.editSplitIndex = null;
      this.accountShow = true;
    },
    //确定新增分账产品
    addSplit() {
      let data = JSON.parse(JSON.stringify(this.addSplitFrom));
      if (!data.settleType) {
        this.$message.error("产品类型必选");
        return;
      }
      if (!data.allocChannelName) {
        this.$message.error("通道名称必填");
        return;
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("费率必填");
        return;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("费率不合法(最多保留小数点后两位)");
          return;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("费率范围（0-100）");
        return;
      }
      if (!data.settleCost && data.settleCost !== 0) {
        this.$message.error("结算成本必填");
        return;
      }
      if (data.settleCost < 0) {
        this.$message.error("结算成本最小金额为0元");
        return;
      }
      if(data.settleCost.length > 8){
        this.$message.error("金额超过最大长度");
        return;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.settleCost))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      this.repeatFlag = true;

      if (this.$route.query.pageType == "edit") {
        data.settleCost *= 100;
        data.allocChannelId = data.allocChannelId ? data.allocChannelId : 0;
        setAllocchannels(this.$route.query.id, data).then((res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");
            this.getDetail();
            this.accountShow = false;
          }
          setTimeout(() => {
            this.repeatFlag = false;
          }, 2000);
        });
      } else {
        if (this.editSplitIndex !== null) {
          this.allocChannels[this.editSplitIndex] = data;
        } else {
          this.allocChannels.push(data);
        }
        setTimeout(() => {
          this.repeatFlag = false;
        }, 2000);
        this.$message.success("保存成功");
        this.accountShow = false;
      }
    },

    // 修改分账
    editSplitTab(value, index) {
      this.addSplitFrom = JSON.parse(JSON.stringify(value));
      this.accountShow = true;
      this.editSplitIndex = index;
    },
    // 删除分账
    delSplitTab(index, value) {
      this.$confirm("此操作将删除该分账产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delAllocchannels(this.$route.query.id, value.allocChannelId).then(
              (res) => {
                if (res.resultStatus) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.allocChannels.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 新增提交
    submit() {
      this.repeatFlag = true;

      if (this.$route.query.pageType == "edit") {
        setOrganizations(this.$route.query.id, this.orgDetail).then((res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");
            // this.$router.go(-1);

            let id = this.$route.query.id;
            this.$router.replace({
              name: "organization_xp",
              query: {
                pageType: "edit",
                id: id,
              },
            });
            setTimeout(() => {
              this.repeatFlag = false;
            }, 2000);
          }
        });
        return;
      }
      let data = {
        orgDetail: JSON.parse(JSON.stringify(this.orgDetail)),
        payChannels: JSON.parse(JSON.stringify(this.payChannels)),
        agnpayChannels: JSON.parse(JSON.stringify(this.agnpayChannels)),
        allocChannels: JSON.parse(JSON.stringify(this.allocChannels)),
      };
      data.agnpayChannels.forEach((v) => {
        v.payCostPublic *= 100;
        v.payCostPrivate *= 100;
      });
      data.allocChannels.forEach((v) => {
        v.settleCost *= 100;
      });
      addOrganizations(data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.$router.replace({
            name: "organization_jggl",
          });
          setTimeout(() => {
            this.repeatFlag = false;
          }, 2000);
        }
      });
    },
    // 点击返回
    getBack() {
      let id = this.$route.query.id;
      this.$router.replace({
        name: "organization_xp",
        query: {
          pageType: "edit",
          id: id,
        },
      });
    },
    // 当前页面切换
    pageClick(value) {
      this.pageActive = value;
      this.crumbs = value;
    },
    // 页面跳转
    pageToUrl(name) {
      this.$router.replace({
        name,
        query: {
          id: this.$route.query.id,
          pageType: "edit",
        },
      });
    },
  },
  beforeDestroy() {
    if (this.$route.query.pageType != "edit") {
      let data = {
        orgDetail: this.orgDetail,
        payChannels: this.payChannels,
        agnpayChannels: this.agnpayChannels,
        allocChannels: this.allocChannels,
      };
      this.setOrgan(data);
    }
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/editInput.css";

.tab1{
  min-height: auto !important;
}
.input_con .inputBox li > span{
  width: 74px;
}
.inputs {
    width: calc(100% - 74px - 10px);
}

.title {
  font-size: 20px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgb(204, 195, 195);
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-radius: 3px;
  border-color: #65b1ae;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
  color: #ffffff;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
.ul_right {
  float: right;
  height: 100%;
  text-align: right;
  margin-right: 28px;
}

.ul_left {
  float: left;
  width: 492px;
  height: 100%;
  text-align: right;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #66b2af;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  border-color: #d7dae2;
  font-size: 12px;
  padding: 0;
}

.bottom {
  margin-top: 50px;
  text-align: center;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
</style>
